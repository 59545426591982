import { Badge } from 'components/Badge'
import { Button } from 'components/Phantom/Button'
import { Type } from 'components/Type'
import { Img } from 'components/basic/Img'
import { VideoGif } from 'components/basic/VideoGif'
import { FC, useRef } from 'react'
import styles from './BasicHero.module.scss'
import { BasicHeroProps } from './BasicHero.types'

export const BasicHero: FC<BasicHeroProps> = (props) => {
	const { config: heroConfig, centered = false, shade = false, leftAlign = false } = props
	const { eyebrow, headline, subheadline, cta, media, renderHeroButton } = heroConfig

	const containerRef = useRef<HTMLDivElement>(null)

	return (
		<section
			className={styles.container}
			ref={containerRef}
			data-centered={centered}
			data-left-align={leftAlign}
		>
			<header className={styles.hero_header}>
				<Badge.Orange>{eyebrow}</Badge.Orange>
				<Type.Headline1>{headline}</Type.Headline1>
				<Type.Headchapter>{subheadline}</Type.Headchapter>
				{cta !== undefined &&
					(renderHeroButton ? (
						renderHeroButton({ cta })
					) : (
						<Button.White
							id={'hero-cta'}
							href={cta.href}
						>
							{cta.text}
						</Button.White>
					))}
			</header>
			<RenderedMedia
				media={media}
				shade={shade}
			/>
		</section>
	)
}

const RenderedMedia: FC<{ media?: BasicHeroProps['config']['media']; shade?: boolean }> = ({ media, shade }) => {
	if (!media) return null
	return (
		<div
			className={styles.media_container_basic}
			data-shade={shade}
		>
			{media.type === 'image' && (
				<Img
					src={media.data.src}
					alt={media.data.alt}
					sources={[
						{
							src: media.data.mobileSrc ?? media.data.src,
							mediaQuery: '(max-width: 1023px)',
							dprHeight: media.data.dprHeight ?? 1500,
							type: 'mobile',
						},
						{
							src: media.data.src,
							mediaQuery: '(min-width: 1023px)',
							dprHeight: media.data.dprHeight ?? 1500,
							type: 'desktop',
						},
					]}
					config={media.data.config}
					doNotCompress={media.data.doNotCompress}
					className={media.className}
					objectFit={'cover'}
				/>
			)}
			{media.type === 'video' && (
				<VideoGif
					src={media.data.src}
					mobileSrc={media.data.mobileSrc}
					poster={media.data.poster}
					loop={media.data.loop}
					className={media.className}
				/>
			)}
		</div>
	)
}
