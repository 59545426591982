export const FragileLogo = (props: { color?: string; className?: string }) => {
	const { color = '#000', className } = props
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			viewBox="0 0 66.9 16"
			className={className}
		>
			<polygon
				fill={color}
				points="26.5 12.8 28.3 12.8 28.3 8.2 32.8 8.2 32.8 6.6 28.3 6.6 28.3 3.9 33.4 3.9 33.4 2.3 26.5 2.3 26.5 12.8"
			/>
			<path
				fill={color}
				d="M35.5,6.8h0v-1.1h-1.6v7.1h1.7v-3.3c0-.9.2-1.4.6-1.8.4-.4.9-.5,1.7-.5h0v-1.6h0c-1.1,0-2,.4-2.4,1.3Z"
			/>
			<path
				fill={color}
				d="M44.3,11.5h0v-3.3c0-1.7-1.1-2.7-2.9-2.7s-2.9.9-3.1,2.3h0c0,0,1.7,0,1.7,0h0c0-.7.6-1.1,1.4-1.1s1.2.4,1.2,1-.2.6-.8.7h-1.1c-1.7.4-2.6,1.1-2.6,2.4s1,2.1,2.5,2.1,1.7-.3,2.2-1h0c0,.3,0,.5,0,.8h0c0,0,1.6,0,1.6,0h0c0-.5,0-.9,0-1.3ZM42.6,10c0,1-.7,1.6-1.6,1.6s-1.1-.4-1.1-.9.4-.8,1.2-1h.8c.4-.2.6-.3.8-.4v.7Z"
			/>
			<path
				fill={color}
				d="M50.6,6.6h0c-.4-.7-1.1-1.1-2.1-1.1-1.9,0-3.2,1.5-3.2,3.6s1.3,3.6,3.2,3.6,1.7-.4,2.1-1.1v1.2c0,1-.6,1.6-1.7,1.6s-1.5-.4-1.6-1.1h0s-1.7,0-1.7,0h0c.1,1.6,1.4,2.5,3.3,2.5s3.4-1.2,3.4-3v-7.1h-1.7v.9ZM48.8,11.4c-1.1,0-1.8-.9-1.8-2.2s.7-2.2,1.8-2.2,1.8.9,1.8,2.2-.7,2.2-1.8,2.2Z"
			/>
			<rect
				fill={color}
				x="53.7"
				y="5.7"
				width="1.7"
				height="7.1"
			/>
			<rect
				fill={color}
				x="53.7"
				y="2.7"
				width="1.8"
				height="1.9"
			/>
			<rect
				fill={color}
				x="56.9"
				y="2.3"
				width="1.7"
				height="10.4"
			/>
			<path
				fill={color}
				d="M66.6,9.2h0c0-2.3-1.3-3.6-3.4-3.6s-3.5,1.5-3.5,3.7,1.4,3.7,3.5,3.7,3-.9,3.3-2.4h0c0,0-1.7,0-1.7,0h0c-.2.6-.8,1-1.6,1-1,0-1.7-.7-1.8-1.8h5.1v-.5ZM61.5,8.4c.2-1.2,1.1-1.6,1.8-1.6s1.6.5,1.6,1.6h-3.4Z"
			/>
			<polygon
				fill={color}
				points="2.7 10.7 2.7 13.3 5.3 13.3 5.3 10.7 5.3 2.7 16 2.7 16 13.3 8 13.3 5.3 13.3 5.3 16 8 16 18.7 16 18.7 0 2.7 0 2.7 10.7"
			/>
			<rect
				fill={color}
				x="0"
				y="13.3"
				width="2.7"
				height="2.7"
			/>
		</svg>
	)
}

export const FragileLogoBoxed = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 53 37.41"
	>
		<rect
			width="53"
			height="37.41"
			rx="6.24"
			ry="6.24"
			fill="#f5f5f5"
		/>
		<polygon
			points="20.29 22.29 21.64 22.29 21.64 18.86 25 18.86 25 17.66 21.64 17.66 21.64 15.64 25.45 15.64 25.45 14.44 20.29 14.44 20.29 22.29"
			fill="#494a4b"
		/>
		<path
			d="M27.02,17.81h0v-.82h-1.2v5.31h1.27v-2.47c0-.67.15-1.05.45-1.35.3-.3.67-.37,1.27-.37h0v-1.2h0c-.82,0-1.5.3-1.79.97v-.07Z"
			fill="#494a4b"
		/>
		<path
			d="M33.6,21.32h0v-2.47c0-1.27-.82-2.02-2.17-2.02s-2.17.67-2.32,1.72h1.27c0-.52.45-.82,1.05-.82s.9.3.9.75-.15.45-.6.52h-.82c-1.27.3-1.94.82-1.94,1.79s.75,1.57,1.87,1.57,1.27-.22,1.64-.75h0v.6h1.2v-.97l-.07.07ZM32.33,20.2c0,.75-.52,1.2-1.2,1.2s-.82-.3-.82-.67.3-.6.9-.75h.6c.3-.15.45-.22.6-.3v.52h-.07Z"
			fill="#494a4b"
		/>
		<path
			d="M38.31,17.66h0c-.3-.52-.82-.82-1.57-.82-1.42,0-2.39,1.12-2.39,2.69s.97,2.69,2.39,2.69,1.27-.3,1.57-.82v.9c0,.75-.45,1.2-1.27,1.2s-1.12-.3-1.2-.82h-1.27c.07,1.2,1.05,1.87,2.47,1.87s2.54-.9,2.54-2.24v-5.31h-1.27s0,.67,0,.67ZM36.97,21.25c-.82,0-1.35-.67-1.35-1.64s.52-1.64,1.35-1.64,1.35.67,1.35,1.64-.52,1.64-1.35,1.64Z"
			fill="#494a4b"
		/>
		<rect
			x="40.63"
			y="16.99"
			width="1.27"
			height="5.31"
			fill="#494a4b"
		/>
		<rect
			x="40.63"
			y="14.74"
			width="1.35"
			height="1.42"
			fill="#494a4b"
		/>
		<rect
			x="43.02"
			y="14.44"
			width="1.27"
			height="7.78"
			fill="#494a4b"
		/>
		<path
			d="M50.27,19.6h0c0-1.72-.97-2.69-2.54-2.69s-2.62,1.12-2.62,2.77,1.05,2.77,2.62,2.77,2.24-.67,2.47-1.79h-1.27c-.15.45-.6.75-1.2.75-.75,0-1.27-.52-1.35-1.35h3.81v-.37l.07-.07ZM46.46,19c.15-.9.82-1.2,1.35-1.2s1.2.37,1.2,1.2h-2.54Z"
			fill="#494a4b"
		/>
		<polygon
			points="4.74 20.72 4.74 22.67 6.69 22.67 6.69 20.72 6.69 14.74 14.69 14.74 14.69 22.67 8.71 22.67 6.69 22.67 6.69 24.69 8.71 24.69 16.71 24.69 16.71 12.73 4.74 12.73 4.74 20.72"
			fill="#494a4b"
		/>
		<rect
			x="2.73"
			y="22.67"
			width="2.02"
			height="2.02"
			fill="#494a4b"
		/>
	</svg>
)
