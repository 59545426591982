import { Button } from 'components/Phantom/Button'
import { Icon, IconName } from 'components/Phantom/Icon'
import { TempDialAnimateUp } from 'components/Phantom/TempDial'
import { Type } from 'components/Type'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'
import { Img } from 'components/basic/Img'
import { VideoGif } from 'components/basic/VideoGif'
import { FC, useRef } from 'react'
import { useMetricRegion } from 'stores/settings'
import styles from './SleepCoolAllNight.module.scss'
import { SleepCoolAllNightProps } from './SleepCoolAllNight.types'

export const SleepCoolAllNightConnected: FC<Partial<SleepCoolAllNightProps>> = (props) => {
	const metricRegion = useMetricRegion()

	return (
		<SleepCoolAllNight
			metric={metricRegion}
			{...props}
		/>
	)
}

export const SleepCoolAllNight: FC<SleepCoolAllNightProps> = (props) => {
	const { metric = false, double = false } = props

	const imageContainerRef = useRef<HTMLDivElement>(null)
	useAnimateInOnScroll(imageContainerRef)

	return (
		<div className={styles.container}>
			<header>
				<Type.Eyebrow
					className={styles.eyebrow}
					animateOnScroll
				>
					Put your sleep on Autopilot
				</Type.Eyebrow>
				<Type.Headline2 animateOnScroll>
					Sleep cool.
					<br />
					All night, every night.
				</Type.Headline2>
				<Type.Body1
					className={styles.subheader}
					animateOnScroll
				>
					Autopilot adjusts your sleeping environment through the night for deeper more refreshing sleep.
				</Type.Body1>
				<Button.Dark
					id={'prospecting-sleep-cool-all-night'}
					href={'/product/pod-cover'}
					animateOnScroll
				>
					Shop now
				</Button.Dark>
			</header>

			<div
				className={styles.image_container}
				ref={imageContainerRef}
			>
				{double ? (
					<VideoGif src={'https://res.cloudinary.com/eightsleep/video/upload/f_auto,q_auto/t_video-gif/sleep_personal_hi_res_f3zlrl.mp4'} />
				) : (
					<Img
						src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/prospecting_c/sleep_cool_every_night.jpg'}
						alt={''}
						objectFit={'cover'}
					/>
				)}
				<TempDialAnimateUp
					endTemp={65}
					metric={metric}
					text={'Your side'}
					className={styles.temp_widget}
				/>
				{double && (
					<TempDialAnimateUp
						endTemp={78}
						metric={metric}
						text={'Their side'}
						className={styles.warm}
					/>
				)}
			</div>

			<ul className={styles.benefits_list}>
				<Benefit
					title={'The perfect temperature all night'}
					description={'As your body temperature changes overnight, Autopilot actively cools and heats each side of the bed independently, keeping you in deep and REM sleep for longer.'}
					icon={'TemperatureLight'}
					color={'black'}
					index={0}
				/>
				<Benefit
					title={'No more night sweats'}
					description={'Engineered to eliminate heat quickly and efficiently, the Pod delivers deeper, uninterrupted sleep—even on the hottest nights.'}
					icon={'CoolLight'}
					color={'black'}
					index={1}
				/>
			</ul>
		</div>
	)
}

const Benefit: FC<{ title: string; description: string; icon: IconName; color: 'black' | 'white'; index: number }> = (props) => {
	const ref = useRef<HTMLLIElement>(null)
	useAnimateInOnScroll(ref, { delay: props.index * 0.1 })

	return (
		<li
			className={styles.benefit}
			key={props.index}
			ref={ref}
		>
			<Icon
				name={props.icon}
				color={props.color}
				size={24}
			/>
			<Type.Headline4
				as={'h3'}
				className={styles.benefit_title}
			>
				{props.title}
			</Type.Headline4>
			<Type.Body1>{props.description}</Type.Body1>
		</li>
	)
}
