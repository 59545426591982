import { FC } from 'react'
import styles from './IndustryLeaders.module.scss'
import { IndustryLeadersProps } from './types'
import { getDefaultPortraits } from 'components/Phantom/_sections/IndustryLeaders/utils'
import { Img } from 'components/basic/Img'
import { Type } from 'components/Type'
import { Button } from 'components/Phantom/Button'

export const IndustryLeaders: FC<IndustryLeadersProps> = (props) => {
	const { portraits = getDefaultPortraits(), theme = 'dark', cta } = props

	return (
		<div
			className={styles.container}
			data-theme={theme}
		>
			<header>
				<Type.Eyebrow
					as={'h2'}
					className={styles.eyebrow}
				>
					Scientific Advisory Board
				</Type.Eyebrow>
				<Type.Headline2
					as={'p'}
					role="heading"
					aria-level={2}
				>
					Backed by industry leaders
				</Type.Headline2>
			</header>

			<ul className={styles.leader_list}>
				{portraits.map((portrait, index) => {
					return (
						<li
							key={index}
							className={styles.leader}
						>
							<Img
								src={portrait.image}
								alt={`Portrait of ${portrait.name}`}
								dprHeight={600}
								objectFit={'cover'}
							/>
							<div className={styles.text}>
								<Type.Headline5 as={'h3'}>{portrait.name}</Type.Headline5>
								<Type.Body2 animateOnScroll>{portrait.title}</Type.Body2>
							</div>
						</li>
					)
				})}
			</ul>

			<div
				className={styles.moire_container}
				style={{ display: theme === 'dark' ? 'block' : 'none' }}
			>
				<img
					src={'https://res.cloudinary.com/eightsleep/image/upload/moire_1_vblw94.png'}
					alt={''}
					role="none"
				/>
				<img
					src={'https://res.cloudinary.com/eightsleep/image/upload/moire_2_op1wus.png'}
					alt={''}
					role="none"
				/>
			</div>

			<div className={styles.cta}>
				{cta && (
					<Button.White
						href={cta.href}
						id={'industry-leaders-cta'}
					>
						{cta.text}
					</Button.White>
				)}
			</div>
		</div>
	)
}
