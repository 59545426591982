import { CTAProps } from 'components/_utils/utilityTypes'
import { Button } from 'components/Phantom/Button'
import { FC } from 'react'
import { useCurrentRegion } from 'stores/settings'
import { BasicHero } from '../BasicHero'
import styles from './Hero.module.scss'
import { HeroConfig } from './Hero.types'

const heroConfigs: Record<string, HeroConfig> = {
	default: {
		headline: 'Nights that fuel your best days',
		subheadline: 'Turn any bed into the ultimate sleeping experience',
		eyebrow: '',
		cta: {
			text: 'Shop now',
			href: '/product/pod-cover',
		},
		media: {
			type: 'image' as const,
			data: {
				src: 'https://res.cloudinary.com/eightsleep/image/upload/blue_walking_hero_color_corrected_mqumiz.png',
				mobileSrc: 'https://res.cloudinary.com/eightsleep/image/upload/blue_walking_hero_color_corrected_mqumiz.png',
				config: {
					desktop: { width: 1024, height: 1024 },
					mobile: { width: 375, height: 700 },
				},
				alt: 'A cool bedroom with the Pod 4 by the bedside',
				doNotCompress: true,
			},
			className: styles.hero_control_image,
		},
	},
	variant: {
		headline: 'Waking up never felt so good',
		media: {
			type: 'image' as const,
			data: {
				src: 'https://res.cloudinary.com/eightsleep/image/upload/daylight-bedroom-fullsize-image-v2b_gptrfx.png',
				mobileSrc: 'https://res.cloudinary.com/eightsleep/image/upload/daylight-bedroom-fullsize-image-v2b_gptrfx.png',
				config: {
					desktop: { width: 1440, height: 1024 },
					mobile: { width: 375, height: 730 },
				},
				alt: 'A warm daylight bedroom with the Pod 4 by the bedside',
				doNotCompress: true,
			},
			className: styles.hero_variant_image,
		},
		renderHeroButton: ({ cta }: { cta: CTAProps }) => (
			<Button.Dark
				id={'hero-cta'}
				href={cta.href}
			>
				{cta.text}
			</Button.Dark>
		),
	},
	ch: {
		headline: 'Now available in Switzerland',
	},
	mx: {
		headline: 'Now available in Mexico',
	},
	ae: {
		headline: 'Nights that fuel your best days',
		media: {
			type: 'image' as const,
			data: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/UAE-Header+1.jpg',
				mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/UAE-Header+1+(1).jpg',
				alt: 'A modern bedroom with the Burj Khalifa in the background',
			},
		},
		useVariant: false,
	},
}

const getHeroConfig = (region: string): Omit<HeroConfig, 'useVariant'> => {
	// First, get the base configuration
	const baseConfig = heroConfigs.default

	// Get region config (or empty object if region doesn't exist)
	const regionConfig = heroConfigs[region] ?? {}

	// Remove useVariant from final config since BasicHero doesn't need it
	const cleanRegionConfig = { ...regionConfig, useVariant: undefined }

	return {
		...baseConfig,
		...cleanRegionConfig,
	}
}

export const HeroConnected: FC = () => {
	const currentRegion = useCurrentRegion()

	const config = getHeroConfig(currentRegion)

	return <BasicHero config={config} />
}
