import { FC, useRef, useState } from 'react'
import styles from './BetterSleepDrawers.module.scss'
import { BetterSleepDrawersItemProps, BetterSleepDrawersProps } from './BetterSleepDrawers.types'
import { Img } from 'components/basic/Img'
import { Button } from 'components/Phantom/Button'
import { Icon } from 'components/Phantom/Icon'
import { Type } from 'components/Type'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'
import cx from 'classnames'

export const BetterSleepDrawers: FC<BetterSleepDrawersProps> = (props) => {
	const { items = getDefaultItems(), cta = { href: '/product/pod-cover', text: 'Shop now' }, theme = 'light' } = props
	return (
		<div
			className={styles.container}
			data-theme={theme}
		>
			<div className={styles.image_container}>
				<Img
					src={'https://res.cloudinary.com/eightsleep/image/upload/lady-in-bed_tzcxgl.png'}
					alt={''}
					objectFit={'cover'}
				/>
				<Type.SmallPrint
					className={cx(styles.disclaimer)}
					animateOnScroll={false}
				>
					*Individual results may vary, and the extent of improvement in sleep quality cannot be guaranteed. Factors such as lifestyle, pre-existing medical conditions, and adherence to product usage
					instructions can influence outcomes. The product is not a medical device.
				</Type.SmallPrint>
			</div>
			<div className={styles.content_container}>
				<header>
					<Type.Eyebrow
						className={styles.eyebrow}
						animateOnScroll
					>
						The Science
					</Type.Eyebrow>
					<Type.Headline2 animateOnScroll>
						Better sleep, clinically proven<sup>*</sup>
					</Type.Headline2>
				</header>

				<ul className={styles.items}>
					{items.map((item) => (
						<DrawerItem
							key={item.title}
							{...item}
						/>
					))}
				</ul>

				<div className={styles.cta_container}>
					{theme == 'dark' ? (
						<Button.White
							id={'better-sleep-drawers-shop-now'}
							href={cta.href}
						>
							{cta.text}
						</Button.White>
					) : (
						<Button.Dark
							id={'better-sleep-drawers-shop-now'}
							href={cta.href}
						>
							{cta.text}
						</Button.Dark>
					)}
					<Button.TextLink
						id={'better-sleep-drawers-learn-more'}
						href={'/pod-cover'}
						className={styles.text_link}
					>
						Learn more
					</Button.TextLink>
				</div>

				<Type.SmallPrint className={styles.disclaimer}>
					*Individual results may vary, and the extent of improvement in sleep quality cannot be guaranteed. Factors such as lifestyle, pre-existing medical conditions, and adherence to product usage
					instructions can influence outcomes. The product is not a medical device.
				</Type.SmallPrint>
			</div>
		</div>
	)
}

const getDefaultItems = () => [
	{
		title: 'Fall asleep faster',
		description: 'Less time trying to fall asleep, more time asleep. Members reported the Pod helped them fall asleep 44% faster',
	},
	{
		title: 'Stay asleep longer',
		description: 'Members reported 23% fewer sleep disturbances and gaining up to 1 hour extra sleep per night',
	},
	{
		title: 'Increase your deep sleep',
		description: 'Through clinical trials, the Pod is proven to deliver up to 34% more deep sleep, and members reported up to 32% higher sleep quality',
	},
	// {
	// 	title: 'Boost your recovery',
	// 	description: 'The Pod tracks and Improves your heart rate variability (HRV) and lowers your sleeping heart rate to give you more energy to do more of what you love',
	// },
]

const DrawerItem: FC<BetterSleepDrawersItemProps> = (props) => {
	const [open, setOpen] = useState(false)

	const ref = useRef<HTMLLIElement>(null)
	useAnimateInOnScroll(ref)

	const paragraphRef = useRef<HTMLParagraphElement>(null)
	const spanRef = useRef<HTMLSpanElement>(null)
	return (
		<li
			className={styles.item}
			data-open={open}
			ref={ref}
		>
			<Button.Empty
				className={styles.item_button}
				id={`better-sleep-drawers-item-${props.title}`}
				onClick={() => setOpen((prev) => !prev)}
			>
				<Type.Headline5
					as={'h3'}
					style={{
						marginBottom: open ? '1.5rem' : '0',
					}}
				>
					{props.title}{' '}
					<Icon
						name={'ChevronDownLight'}
						color={'black'}
					/>
				</Type.Headline5>
				<Type.Body1
					ref={paragraphRef}
					style={{
						visibility: open ? 'visible' : 'hidden',
						marginTop: 0,
						maxHeight: open ? `${spanRef.current?.getBoundingClientRect().height * 1.25}px` : '0',
					}}
				>
					<span ref={spanRef}>{props.description}</span>
				</Type.Body1>
			</Button.Empty>
		</li>
	)
}
