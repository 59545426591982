'use client'

import { FeaturedReviews } from 'components/Phantom/_sections/FeaturedReviews'
import { IndustryLeaders } from 'components/Phantom/_sections/IndustryLeaders'
import { NewStandardConnected } from 'components/Phantom/_sections/NewStandard'
import { PersonalSleepConnected } from 'components/Phantom/_sections/PersonalSleep'
import { ScrollSequenceStatic } from 'components/Phantom/_sections/ScrollSequenceStatic'
import { WhoWeAre } from 'components/Phantom/_sections/WhoWeAre'
import { ProductBreakdownConnected } from 'components/ProductBreakdown'
import { SplitFeatureFragile, SplitFeatureRiskFree } from 'components/SplitFeature'
import { BetterSleepDrawers } from 'pageCores/prospectingCMin/localComponents'
import { GeneralUserReviews } from 'pageCores/SleepElixirLP/components/UserReviews'
import { HeroConnected } from './components'
import { Awards } from './components/Awards'

const Homepage = () => {
	return (
		<>
			<HeroConnected />
			<NewStandardConnected
				ctaUrl="/pod-cover"
				afterIntelligentSystem
			/>
			<ProductBreakdownConnected theme={'light'} />
			<SplitFeatureRiskFree />
			<FeaturedReviews title={['Trusted by those who', 'demand the best in sleep']} />
			<IndustryLeaders theme={'light'} />
			<PersonalSleepConnected
				showExtraSleep={true}
				header={'Your perfect sleep conditions, all night'}
				subHeader={"The Pod personalizes your side of the bed, creating the ultimate environment for deep, restorative sleep—even if you and your partner can't agree on the perfect temperature."}
				cta={{
					href: '/product/pod-cover',
					text: 'Shop now',
				}}
			/>
			{/* <AthletesSlider
				athletes={['charles-leclerc', 'taylor-fritz', 'tricia-mangan', 'sidney-crosby', 'brooke-wells', 'danny-green', 'richard-carapaz', 'chelsea-gray', 'ryan-murphy', 'justin-medeiros']}
				cta={{ href: '/athletes', text: 'Learn more' }}
				header="Champions sleep on Eight&nbsp;Sleep"
				hideArrows={true}
			/> */}
			<WhoWeAre />
			<GeneralUserReviews />
			<BetterSleepDrawers
				theme={'dark'}
				cta={{ href: '/product/pod-cover', text: 'Shop now' }}
			/>
			<SplitFeatureFragile />
			<ScrollSequenceStatic ctaUrl="/product/pod-cover" />
			<Awards theme={'light'} />
		</>
	)
}

export default Homepage
