import { PortraitProps } from './types'

export const getDefaultPortraits = (): PortraitProps[] => {
	return [
		{
			name: 'Peter Attia, MD',
			title: 'Physician and founder of Early Medical',
			image: 'https://res.cloudinary.com/eightsleep/image/upload/attia_advisory_no_crop_be5ruq.png',
		},
		{
			name: 'Andrew Huberman, Ph.D.',
			title: 'Neuroscientist and Stanford professor',
			image: 'https://res.cloudinary.com/eightsleep/image/upload/huberman-color_pvaoar.png',
		},
		{
			name: 'Matthew Walker, Ph.D.',
			title: 'Neuroscientist & UC Berkeley professor',
			image: 'https://res.cloudinary.com/eightsleep/image/upload/walker-color_agjthm.png',
		},
	]
}
